import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import Ad from '@ifeng-fe/ui_pc_ad';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import Promote from '../../../components/promote';

class LeftNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { sportsTitle, sportsList, shopInSports, sportsHardAd, promoteInSports },
        } = this.props;

        return (
            <div className={styles.left_box}>
                <Chip id="200141" type="struct" title="体育标题" groupName="体育" content={sportsTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="225746"
                    type="recommend"
                    title="体育列表"
                    groupName="体育"
                    content={sportsList}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                <ShopList size="long" content={shopInSports} sourceName="shopInSports" />
                <NoSSR>
                    <Ad content={sportsHardAd} styleName={styles.dbox} />
                </NoSSR>
                {promoteInSports && (
                    <Chip id="170182" type="struct" title="铺底推广" groupName="体育" content={promoteInSports}>
                        <Promote />
                    </Chip>
                )}
            </div>
        );
    }
}

export default errorBoundary(LeftNewsBox);
