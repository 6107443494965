import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';
import AutoArea from './autoArea';
import { getAutoAreaData } from '../../../../utils/autoArea';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        autoAreaData: '',
    };

    async componentDidMount() {
        const autoAreaData = await getAutoAreaData();

        this.setState({
            autoAreaData,
        });
    }

    render() {
        const { autoAreaData } = this.state;
        const {
            content: { autoTitle, autoListTitle, autoList0, autoArea, autoList1, shopInAuto, autoListAd },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip id="230184" type="struct" title="汽车标题" groupName="汽车" content={autoTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="255931"
                    type="recommend"
                    title="汽车头条"
                    groupName="汽车"
                    content={autoListTitle}
                    translate="handleNewsData">
                    <NewsList hasTitle size="long" />
                </Chip>
                <Chip
                    id="195856"
                    type="recommend"
                    title="汽车列表1"
                    groupName="汽车"
                    content={autoList0}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                <Chip
                    id="255932"
                    type="recommend"
                    title="汽车地方站"
                    groupName="汽车"
                    content={autoArea}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                {autoAreaData && autoAreaData !== '' ? (
                    <AutoArea content={autoAreaData} />
                ) : (
                    <Chip
                        id="255866"
                        type="recommend"
                        title="汽车栏新闻列表2"
                        groupName="汽车栏"
                        content={autoList1}
                        translate="handleNewsData">
                        <NewsList size="long" />
                    </Chip>
                )}
                <ShopList size="long" ad={autoListAd} content={shopInAuto} sourceName="shopInAuto" />
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
