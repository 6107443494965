import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import { cookie } from '@ifeng-fe/ui_base';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ContentList from './contentList';
import TabPane from './tabPane';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    state = {
        areaTab: {},
        localKey: null,
        current: 0,
    };

    componentDidMount() {
        const {
            content: { newsTitle },
        } = this.props;

        try {
            let areaTab = {};
            let localKey = null;

            let prov = null;
            let city = null;

            if (typeof document !== 'undefined') {
                prov =
                    cookie.get('prov') && cookie.get('prov') !== '' && cookie.get('prov') !== 'cn010'
                        ? cookie.get('prov')
                        : null;
                city =
                    cookie.get('city') && cookie.get('city') !== '' && cookie.get('city') !== '010'
                        ? cookie.get('city')
                        : null;
            }

            if (newsTitle.length > 0 && prov && city) {
                const areaProvArr = newsTitle.filter(item => item.isShow).filter(item => item.prov === prov);
                const areaCityArr = areaProvArr.filter(item => item.city === city);

                if (areaCityArr.length > 0) {
                    areaTab = areaCityArr?.[0];
                    localKey = areaTab?.localKey;
                } else {
                    areaTab = areaProvArr?.[0];
                    localKey = areaTab?.localKey;
                }
            }

            if (typeof window !== 'undefined') {
                const path = window.location.href;

                // 地方站预览逻辑
                if (path.indexOf('visualediting') > -1 || path.indexOf('preview') > -1) {
                    const key = path.split('/')[path.split('/').length - 1];

                    const arr = newsTitle.filter(item => item.localKey === key);

                    if (arr.length > 0) {
                        areaTab = arr[0];
                        localKey = key;
                    }
                }
            }

            this.setState({
                areaTab: { ...areaTab },
                localKey,
            });
        } catch (e) {
            console.error(e);
        }
    }

    render() {
        const { areaTab, localKey, current } = this.state;
        const {
            content: {
                newsTitle,
                newsTop,
                newsList0,
                newsList1,
                newsList2,
                shopInNews0,
                shopInNews1,
                areaList,
                govList,
                newsAd,
            },
        } = this.props;

        const moreData = newsTitle.filter(item => item.moreImg);

        const tabs = localKey ? [newsTitle[0], ...moreData, { ...areaTab }] : [newsTitle[0], ...moreData];

        return (
            <div className={styles.yaowen_center_box}>
                <NewsTitle size="middle" type="tab" content={[...tabs]} current={current}>
                    {tabs.map((item, index) => (
                        <TabPane key={index} tab={item.title} url={item.url}>
                            {index === 0 ? (
                                <>
                                    <ContentList
                                        content={{
                                            newsTop,
                                            newsAd,
                                            newsList0,
                                            newsList1,
                                            newsList2,
                                            shopInNews0,
                                            shopInNews1,
                                        }}
                                    />
                                    {areaList[`${localKey}Last`] ? (
                                        <NewsList size="long" content={areaList[`${localKey}Last`]} />
                                    ) : (
                                        <Chip
                                            id="195851"
                                            type="recommend"
                                            title="要闻列表3"
                                            groupName="要闻区"
                                            content={newsList2}
                                            translate="handleNewsData">
                                            <NewsList size="long" />
                                        </Chip>
                                    )}
                                    <Chip
                                        id="285556"
                                        type="recommend"
                                        title="政务列表"
                                        groupName="要闻区"
                                        content={govList}
                                        translate="handleGovListData">
                                        <NewsList size="long" />
                                    </Chip>
                                </>
                            ) : (
                                <>
                                    <NewsList hasTitle isBig size="long" content={areaList[localKey]} />
                                    {areaList[`${localKey}Last`] ? (
                                        <NewsList size="long" content={areaList[`${localKey}Last`]} />
                                    ) : (
                                        <Chip
                                            id="195851"
                                            type="recommend"
                                            title="要闻列表3"
                                            groupName="要闻区"
                                            content={newsList2}
                                            translate="handleNewsData">
                                            <NewsList size="long" />
                                        </Chip>
                                    )}
                                </>
                            )}
                        </TabPane>
                    ))}
                </NewsTitle>
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
