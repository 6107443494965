import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../../utils/rel';

class AutoSearch extends React.PureComponent {
    state = {
        searchTxt: '输入品牌或车系',
    };

    handleChange = e => {
        const val = e.currentTarget.value;

        this.setState({ searchTxt: val });
    };

    handleFocus = e => {
        const val = e.currentTarget.value;

        this.setState({
            searchTxt: val === '输入品牌或车系' ? '' : val,
        });
    };

    handleBlur = e => {
        const val = e.currentTarget.value;

        this.setState({
            searchTxt: val === '' ? '输入品牌或车系' : val,
        });
    };

    render() {
        const { searchTxt } = this.state;

        return (
            <div className={styles.auto_search}>
                <form method="get" action={'https://data.auto.ifeng.com/search/search.do?'} target="_blank" rel={rel}>
                    <input
                        className={styles.text}
                        type="text"
                        name="keywords"
                        value={searchTxt}
                        onChange={this.handleChange}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                    />
                    <input className={styles.btn} type="submit" value="搜索" />
                </form>
            </div>
        );
    }
}

export default errorBoundary(AutoSearch);
