import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { eduTitle, eduList0, shopInHealth, homeList },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="260706" type="struct" title="教育和家居标题" groupName="教育和家居" content={eduTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip
                    id="287961"
                    type="recommend"
                    title="教育列表"
                    groupName="教育"
                    content={eduList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="short" />
                </Chip>
                <ShopList size="short" content={shopInHealth} sourceName="shopInHealth" />
                <Chip
                    id="287962"
                    type="recommend"
                    title="家居列表"
                    groupName="家居"
                    content={homeList}
                    translate="handleNewsData">
                    <NewsList size="short" />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
