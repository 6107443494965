import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../../utils/rel';

class AutoTitle extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.auto_title}>
                <ul className="clearfix">
                    {content.map((item, index) => (
                        <li key={index}>
                            <a href={item.url} target="_blank" rel={rel}>
                                {item.title}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default errorBoundary(AutoTitle);
