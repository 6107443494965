import React from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { financeTitle, financeList0, shopInStock, stockList1, stockListAd },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip id="200139" type="struct" title="财经标题" groupName="财经" content={financeTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="195853"
                    type="recommend"
                    title="财经列表1"
                    groupName="财经"
                    content={financeList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                <ShopList size="long" content={shopInStock} sourceName="shopInStock" />
                <Chip
                    id="225742"
                    type="recommend"
                    title="财经列表2"
                    groupName="财经"
                    content={stockList1}
                    translate="handleNewsData">
                    <NewsList size="long" ad={stockListAd} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
