import React, { Fragment } from 'react';
import NoSSR from 'react-no-ssr';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsList from '../../../../components/newsList';
import ShopList from '../../../../components/shopList';

class ContentList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { newsTop, newsList0, newsList1, shopInNews0, shopInNews1, newsAd },
        } = this.props;

        return (
            <Fragment>
                <NoSSR>
                    <Chip
                        id="255934"
                        type="recommend"
                        title="要闻置顶"
                        groupName="要闻区"
                        content={newsTop}
                        translate="handleNewsTopData">
                        <NewsList hasTitle isUnfold size="long" from="headlines" />
                    </Chip>
                </NoSSR>
                <NoSSR>
                    <Chip
                        id="285555"
                        type="recommend"
                        title="要闻列表1"
                        groupName="要闻区"
                        content={newsList0}
                        translate="handleNewsListData">
                        <NewsList size="long" isUnfold ad={newsAd} noChildren={true} />
                    </Chip>
                </NoSSR>
                <Chip
                    id="260754"
                    type="struct"
                    title="要闻区电商1"
                    groupName="要闻区"
                    content={shopInNews0}
                    translate="handleShopStructData">
                    <ShopList from="focusNews" size="long" sourceName="shopInNews0" />
                </Chip>
                <Chip
                    id="225732"
                    type="recommend"
                    title="要闻列表2"
                    groupName="要闻区"
                    content={newsList1}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
                <Chip
                    id="260755"
                    type="struct"
                    title="要闻区电商2"
                    groupName="要闻区"
                    content={shopInNews1}
                    translate="handleShopStructData">
                    <ShopList from="focusNews" size="long" sourceName="shopInNews1" />
                </Chip>
            </Fragment>
        );
    }
}

export default errorBoundary(ContentList);
