import React from 'react';
import PropTypes from 'prop-types';
import { formatImageWebpAuto } from '@ifeng-fe/public_method';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '../../../../../utils/rel';

class AutoList extends React.PureComponent {
    static propTypes = {
        content: PropTypes.array,
    };

    render() {
        const { content } = this.props;

        return (
            <div className={styles.auto_list}>
                <ul className="clearfix">
                    {content.slice(0, 4).map((item, index) => (
                        <li key={index}>
                            <a href={item.url} target="_blank" rel={rel}>
                                <img src={formatImageWebpAuto(item.src, 81, 48)} title={item.title} alt={item.title} />
                                <p>{item.title}</p>
                            </a>
                        </li>
                    ))}
                </ul>
                <ul className="clearfix">
                    {content.slice(4).map((item, index) => (
                        <li key={index}>
                            <a href={item.url} target="_blank" rel={rel}>
                                <img src={formatImageWebpAuto(item.src, 81, 48)} title={item.title} alt={item.title} />
                                <p>{item.title}</p>
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }
}

export default errorBoundary(AutoList);
