import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import NoSSR from 'react-no-ssr';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import AutoTitle from './autoTitle';
import AutoList from './autoList';
import AutoSearch from './autoSearch';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { newCarGuideTitle, newCarGuidePrice, newCarGuideType, newCarGuideList, newCarGuideListAd },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="230185" type="struct" title="新车导购标题" groupName="新车导购" content={newCarGuideTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip id="50067" type="struct" title="新车导购标题" groupName="新车导购" content={newCarGuidePrice}>
                    <AutoTitle />
                </Chip>
                <NoSSR>
                    <Chip
                        id="50091"
                        type="struct"
                        title="新车导购价位区间"
                        groupName="新车导购"
                        content={newCarGuideType}>
                        <AutoList />
                    </Chip>
                </NoSSR>
                <AutoSearch />
                <Chip
                    id="195857"
                    type="recommend"
                    title="新车导购列表"
                    groupName="新车导购"
                    content={newCarGuideList}
                    translate="handleNewsData">
                    <NewsList size="short" ad={newCarGuideListAd} />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
