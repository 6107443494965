import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';

class RightNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { jiuTitle, shopRegionList1, shopRegionList2, shopRegionList3, shopRegionList4, jiuList, shopAd },
        } = this.props;

        return (
            <div className={styles.right_box}>
                <Chip id="200143" type="struct" title="电商酒业标题" groupName="电商酒业" content={jiuTitle}>
                    <NewsTitle size="short" />
                </Chip>
                <Chip
                    id="285492"
                    type="recommend"
                    title="电商列表1"
                    groupName="电商酒业"
                    content={shopRegionList1}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="short" />
                </Chip>
                <ShopList size="short" content={shopRegionList2} sourceName="shopRegionList2" />
                <Chip
                    id="285494"
                    type="recommend"
                    title="电商列表3"
                    groupName="电商酒业"
                    content={shopRegionList3}
                    translate="handleNewsData">
                    <NewsList size="short" />
                </Chip>
                <Chip
                    id="225756"
                    type="recommend"
                    title="酒业列表"
                    groupName="酒业"
                    content={jiuList}
                    translate="handleNewsData">
                    <NewsList size="short" />
                </Chip>
                <ShopList size="short" ad={shopAd} content={shopRegionList4} sourceName="shopRegionList4" />
            </div>
        );
    }
}

export default errorBoundary(RightNewsBox);
