import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import Chip from 'Chip';
import errorBoundary from '@ifeng-fe/errorBoundary';
import NewsTitle from '../../../components/newsTitle';
import NewsList from '../../../components/newsList';
import ShopList from '../../../components/shopList';

class CenterNewsBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const {
            content: { foTitle, foList0, foList1, shopInGuoxue },
        } = this.props;

        return (
            <div className={styles.center_box}>
                <Chip id="200145" type="struct" title="佛教国学标题" groupName="佛教国学" content={foTitle}>
                    <NewsTitle size="middle" />
                </Chip>
                <Chip
                    id="285095"
                    type="recommend"
                    title="佛教列表1"
                    groupName="佛教国学"
                    content={foList0}
                    translate="handleNewsData">
                    <NewsList hasTitleImg size="long" />
                </Chip>
                <ShopList size="long" content={shopInGuoxue} sourceName="shopInGuoxue" />
                <Chip
                    id="285096"
                    type="recommend"
                    title="佛教列表2"
                    groupName="佛教国学"
                    content={foList1}
                    translate="handleNewsData">
                    <NewsList size="long" />
                </Chip>
            </div>
        );
    }
}

export default errorBoundary(CenterNewsBox);
